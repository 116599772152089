import api from '@services/api'

export default {
  list(axios, params, query, config) {
    return api.get(axios, '/manager/machines/models', params, query, config)
  },

  get(axios, params, query, config) {
    return api.get(axios, '/manager/machines/models/:id', params, query, config)
  },

  create(axios, params, payload, config) {
    return api.post(axios, '/manager/machines/models', params, payload, config)
  },

  update(axios, params, payload, config) {
    return api.update(axios, '/manager/machines/models/:id', params, payload, config)
  },

  destroy(axios, params, config) {
    return api.delete(axios, '/manager/machines/models/:id', params, config)
  },

  searchCategory(axios, params, query, config) {
    return api.get(axios, '/manager/machines/models/search_category', params, query, config)
  },

  attachment(axios, params, payload, config) {
    return api.update(axios, '/manager/machines/models/:id/attachment', params, payload, config)
  },
}
